import React from "react";
import {
  ResponsiveContainer,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import moment from "moment/moment";

import {
  TooltipContainer,
  DateLabel,
  NoGraphDataCont,
  Value,
  GraphCont,
} from "./LineChartComp.styles";
import { COMP_DIMENSIONS } from "utils/constants";
import useScrollLock from "hooks/useScrollLock";

const LineChartComp = ({ data, fromMonthsBefore, graphColor }) => {
  const { lockScroll, unlockScroll } = useScrollLock();

  const convertToDate = (d) => {
    const [day, month, year] = d.split("-");
    return new Date(year, month - 1, day);
  };

  const minValue =
    fromMonthsBefore === "all"
      ? null
      : moment().subtract(fromMonthsBefore, "months").format("DD-MM-YYYY");

  const selectedData =
    fromMonthsBefore === "all"
      ? data
      : data.filter(
          (curr) => convertToDate(curr.date) > convertToDate(minValue)
        );

  return (
    <GraphCont>
      <ResponsiveContainer height={COMP_DIMENSIONS.HEIGHTS.graph}>
        {selectedData.length === 0 ? (
          <NoGraphDataCont>
            <p>No Graph Data Available</p>
          </NoGraphDataCont>
        ) : (
          <LineChart
            data={selectedData}
            onMouseDown={lockScroll}
            onMouseUp={unlockScroll}
          >
            <YAxis
              dataKey={"nav_price"}
              hide={true}
              type="number"
              domain={["auto", "auto"]}
              allowDataOverflow={true}
            />
            <XAxis dataKey={"date"} hide={true} />
            <Line
              type="monotone"
              dataKey="nav_price"
              stroke={graphColor}
              strokeWidth={1}
              dot={false}
            />
            <Tooltip content={<CustomTooltip />} />
          </LineChart>
        )}
      </ResponsiveContainer>
    </GraphCont>
  );
};

function CustomTooltip({ active, payload, label }) {
  if (active) {
    return (
      <TooltipContainer>
        <DateLabel>{label}</DateLabel>
        <Value>NAV: ₹{payload[0]?.payload.nav_price}</Value>
      </TooltipContainer>
    );
  } else {
    return null;
  }
}

export default LineChartComp;
