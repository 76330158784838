
// import InputField from "components/common/InputField/InputField";
import MultipleSelectAccordion from "components/common/MultipleSelectAccordion/MultipleSelectAccordion";

import React, { useState } from "react";

const Test = () => {
  const handleChange = (e,value )=>{
    if(options.includes(value.name)){
      let arr=options.filter(el=>el!==value.name)
      setoptions(arr)
    }
    else{setoptions([...options,value.name])}
   
  }

  const [options, setoptions] = useState([])

  
  return (
    <div
      style={{
        marginTop: "8rem",
        width:"30rem"
      }}
    >
      {/* <InputField/> */}
      
      <MultipleSelectAccordion
      showSearchBar
        heading={"AMCs"}
        options={[
          { name: "Karnam" },
          { name: "Praveenkumar" },
          { name: "Ratnakar" },
        ]}
        selectedOptions={options}
        // heading={<InputField label={"AMCs"}/>}
        handleChange={handleChange}

        
      />
      
    </div>
  );
};

export default Test;
