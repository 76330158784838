import styled from "styled-components/macro";
import {
  AlignCenter,
  Centering,
  SpaceBetween,
  SpaceBetweenAlignCenter,
} from "styles/common-styles";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const NavBarCont = styled.div`
  ${SpaceBetween}
  padding: 2rem 18rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  z-index: 999;
  height: 80px;

  @media (max-width: 1396px) {
    padding: 2rem 4rem;
  }

  @media (max-width: 980px) {
    display: ${({ mobileNav }) => (mobileNav ? "flex" : "none")};
    padding: 2rem;
  }
`;

export const Logo = styled(LazyLoadImage)`
  width: 16rem;
`;

export const NavLink = styled.p`
  cursor: pointer;
  color: ${({ active, theme }) => (active ? "var(--themeColor)" : theme.text)};
  font-weight: 500;
`;

export const NavRight = styled.div`
  ${AlignCenter};
  gap: 4rem;
`;

export const AccountOptions = styled.div`
  width: 24vw;
  background-color: ${({ theme }) => theme.background};
  box-shadow: rgba(32, 178, 171, 0.2) 0px 7px 29px 0px;
  position: fixed;
  right: 18rem;
  top: 8rem;
  border-radius: 4px;
  /* border: ${({ theme }) => `1px solid ${theme.borderColor}`}; */
  overflow: hidden;

  -webkit-transition: height 1s ease;
  -moz-transition: height 1s ease;
  -o-transition: height 1s ease;
  transition: height 1s ease;
  @media (max-width: 1396px) {
    right: 4rem;
  }

  @media (max-width: 980px) {
    display: ${({ mobileNav }) => (mobileNav ? "block" : "none")};
    right: 2rem;
  }
`;

export const OptionsTop = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  background-color: ${({ theme }) => theme.background};
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor}`};

  @media (max-width: 980px) {
    padding: 1rem 0;
    margin-bottom: 2rem;
    gap: 4px;
    flex-wrap: wrap;
  }

  @media (min-width: 990px) and (max-width: 1400px) {
    margin-bottom: 2rem;
    gap: 4px;
    flex-wrap: wrap;
  }
`;

export const AccountOption = styled.div`
  cursor: pointer;
  padding: 1.4rem 2rem;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColor2}`};
  display: flex;
  gap: 1.4rem;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.borderColor};
  }

  @media (max-width: 980px) {
    padding: 1.4rem 0;
  }
`;

export const AccountOptionCont = styled.div`
  ${Centering}
  height: 4rem;
  width: 4rem;
  background-color: ${({ theme }) => theme.cardBackground2};
  border-radius: 50%;
  color: ${({ theme }) => theme.text};
`;

// MOBILE

export const MobileNavBarCont = styled.div`
  display: none;
  @media (max-width: 980px) {
    display: block;
    ${SpaceBetweenAlignCenter}
    padding:6px;
    margin: 6px;
    border: ${({ theme }) => `1px solid ${theme.searchBorder}`};
    border-radius: 100px;
  }
`;
