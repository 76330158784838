import styled from "styled-components";
import {
  Centering,
  SpaceBetween,
  SpaceBetweenAlignCenter,
} from "styles/common-styles";
import { StyledLink, padding } from "styles/common-styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const ContAndFooter = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  ${SpaceBetween};
  gap: 6rem;
  ${padding}

  @media (max-width: 980px) {
    flex-direction: column-reverse;
  }
`;

export const TopFundCont = styled.div`
  ${SpaceBetweenAlignCenter}
  gap: 1rem;
  margin-bottom: 4rem;
  overflow: auto;
  padding: 2rem 4px;

  @media (max-width: 980px) {
    width: 100%;
    padding: 2rem 0;
  }
`;

export const TopFundCard = styled(StyledLink)`
  box-shadow: 0 1px 5px 0 ${({ theme }) => theme.boxShadow};
  padding: 1.4rem;
  border-radius: 8px;
  height: 17rem;
  flex: 1;
  max-width: 20rem;
  cursor: pointer;
  transition: transform 0.3s ease;
  flex-direction: column;
  ${SpaceBetween}
  background-color:${({ theme }) => theme.cardBackground};

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 980px) {
    min-width: 20rem;
    min-height: 20rem;
  }
`;

export const TopFundImgCont = styled.div`
  width: 3rem;
  height: 3rem;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 6px;
  margin-bottom: 1rem;
  background-color: #fff;
  ${Centering}
`;

export const ExploreAll = styled.div`
  width: 100%;
  box-shadow: 0 1px 5px 0 ${({ theme }) => theme.boxShadow};
  margin-bottom: 6rem;
  overflow: hidden;
  border-radius: 4px;
  ${SpaceBetweenAlignCenter}
  cursor: pointer;
  background-color: ${({ theme }) => theme.cardBackground};

  @media (max-width: 980px) {
    height: 60vh;
    flex-direction: column-reverse;
    text-align: center;
  }
`;

export const ExploreAllImg = styled(LazyLoadImage)`
  width: 50%;
  height: auto;

  @media (max-width: 980px) {
    width: 100%;
  }
`;

export const ExploreContent = styled.div`
  padding: 2rem;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
`;

export const CollectionCont = styled.div`
  margin: 2rem 0;
  gap: 6rem;
  ${SpaceBetween}

  @media (max-width: 980px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    row-gap: 2rem;
  }
`;

export const CollectionCard = styled.div`
  cursor: pointer;
  transition: transform 0.3s ease;
  position: relative;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  box-shadow: ${({ theme }) => `0 1px 5px 0 ${theme.boxShadow}`};
  ${Centering}
  flex-direction: column;
  background-color: ${({ theme }) => theme.cardBackground};
`;

export const CompleteSetupCardCont = styled.div`
  box-shadow: ${({ theme }) => `${theme.boxShadow} 0px 4px 12px`};
  border-radius: 4px;
  text-align: center;
  ${Centering}
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0;
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.cardBackground};

  @media (max-width: 980px) {
    position: static !important;
    width: 100%;
  }
`;

export const InvestmentsSummaryCard = styled(CompleteSetupCardCont)`
  padding: 2rem;
  width: 100%;
`;

export const CompleteSetupCardContent = styled.div`
  flex: 1;
  padding: 0 2rem;
`;

export const CollectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const CategoryCard = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  transition: transform 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease;

  &:hover {
    cursor: pointer;
    transform: scale(1.025);
    background-color: #f8f9fa;
    border-color: var(--themeColor);
  }

  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const IconContainer = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.8rem;
  background-color: var(--transparentThemeColor);
  padding: 1rem;
  border-radius: 8px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.h6`
  margin: 0 0 5px;
  font-size: 1.2rem;
  font-weight: 600;
`;

export const Description = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #555;
`;
