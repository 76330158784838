import styled from "styled-components/macro";
import { centering } from "styles/common-styles";

export const Container = styled.div`
  border: 1px solid #e9ecef;
  border-radius: 4px;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  box-sizing: border-box;
`;

export const TopRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

export const MetricBoxLabel = styled.div`
  padding: 2rem;
  border-bottom: 1px solid #e9ecef;
  background-color: #f8f9fa;

  @media (max-width: 600px) {
    min-width: 24rem;
  }
`;

export const MetricBoxValue = styled(MetricBoxLabel)`
  padding: 1rem 2rem;
  border-left: 1px solid #e9ecef;
  background-color: var(--white);
  display: flex;
  align-items: center;
`;
